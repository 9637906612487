<template>
  <div class="biz-container">
    <ModalDetailProduct2
      :productDetail="productDetail"
      :showModal="showModalDetailProduct"
      :propsIsEdit="false"
      :returId="returId"
      :getStatusRetur="getStatusRetur"
      :bgColor="bgColor"
      :dataDetailRetur="dataDetailRetur"
      @emitShowModal="emitShowModalDetailProduct"
    />
    <div class="ant-modal-root order-detail_loader" v-if="loading">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin></a-spin>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-start align-items-center mb-3">
        <div>
          <a-icon @click.prevent="$router.push({ path: '/account/retur' })" type="arrow-left"
            style="font-size: 20px; color: #000000; cursor: pointer" />
        </div>
        <div class="ml-3">
          <h2> {{ $t('menu.retur.list.return_request_detail') }}</h2>
        </div>
      </div>
      <div class="px-5">
        <a-row>
          <a-col :span="24" class="payment-information px-3 py-3 mb-4">
            <a-row type="flex" wrap :justify="isMobileView ? 'space-around' : 'center'">
              <div class="d-flex align-items-center flex-column justify-content-between" style="width: 20%"
                v-for="({ title, active, component, code }, key) in listProcess" :key="key">
                <div>
                  <component v-bind:is="component" :key="key" :active="active"></component>
                </div>
                <div class="mt-2" style="font-weight: 400"
                  :class="active ? (code.includes('CANCELED') || code.includes('REJECTED')) ? 'text-cancel' : 'text-icon' : 'text-icon-pending'"> {{
                      title
                  }} </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
        <div class="mb-3 sub-title">
          Informasi Retur
        </div>
        <div class="d-flex justify-content-between container-info-retur">
          <div class="d-flex w-50 align-items-center">
            <p class="w-50 label">No. Retur</p>
            <p class="mr-2 value">:</p>
            <p class="w-50 value">{{ dataDetailRetur && dataDetailRetur.return_number }}</p>
          </div>
          <div class="d-flex w-50 justify-content-end align-items-center">
            <p class="w-25 label">Distributor</p>
            <p class="mr-2 value">:</p>
            <div class="w-50">
              <div class="d-flex align-self-end align-items-center">
                <WarehousePinIcon :width="'25'" :height="'25'" color="var(--biz-secondary-100)"/>
                <div class="warehouse-name ml-2">{{ dataDetailRetur && dataDetailRetur.seller_name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between container-info-retur mt-2">
          <div class="d-flex w-50 align-items-center">
            <p class="w-50 label">Tanggal Pengajuan</p>
            <p class="mr-2 value">:</p>
            <p class="w-50 value">{{ getRequestReturDate }}</p>
          </div>
          <div class="d-flex w-50 justify-content-end align-items-center">
            <p class="w-25 label">Status</p>
            <p class="mr-2 value">:</p>
            <div class="w-50">
              <div class="px-3 py-1 tag-status" :style="{ background: bgColor[dataDetailRetur && dataDetailRetur.status].bg, color: bgColor[dataDetailRetur && dataDetailRetur.status].color }">{{ getStatusRetur }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between container-info-retur mt-2" v-if="dataDetailRetur && dataDetailRetur.processed_date">
          <div class="d-flex w-50 align-items-center">
            <p class="w-50 label">Tanggal Diproses</p>
            <p class="mr-2 value">:</p>
            <p class="w-50 value">{{ getProcessReturDate }}</p>
          </div>
        </div>
      </div>
      <div class="px-5 mt-5">
        <div class="d-flex justify-content-between mb-3 mt-3">
          <div class="mb-3 sub-title">
            Produk Yang Diretur
          </div>
          <div class="reason-reject d-flex" v-if="dataDetailRetur.status === 'REJECTED'">
            Alasan Ditolak
            <div class="text-dark ml-3 mr-3">
              : {{ dataDetailRetur.notes_header }}
            </div>
            <CloseIcon :width="'20'" :height="'20'"/>
          </div>
        </div>
        <div style="overflow-x: auto;">
          <table style="width:100%; min-width: 1000px;">
            <tr class="text-left table-header" style="border-radius: 10px;">
              <th class="px-2"> No </th>
              <th> Nama Produk </th>
              <th>
                <span>
                  Req QTY
                </span>
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>jumlah item yang di request</span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </th>
              <th v-if="dataDetailRetur.status === 'ACCEPTED'" class="text-center">
                <span>
                  App QTY
                </span>
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>jumlah item yang di approve</span>
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </th>
              <th> Harga Satuan </th>
              <th> Total Harga </th>
              <th width="100"> </th>
            </tr>
            <template v-if="listProductRetur?.length > 0">
              <tr class="text-left tr-product-list table-body" v-for="(obj, index) in listProductRetur" :key="index">
                <td class="px-2">{{ index+1 }}</td>
                <td width="400">
                  <div class="d-flex align-items-center py-3">
                    <img
                      alt="image-product"
                      :src="obj && obj.img_url"
                      style="width: 50px; height: 50px; object-fit: cover;"
                    />
                    <div class="ml-2 w-100">{{ obj && obj.catalog_title}}</div>
                  </div>
                </td>
                <td width="" class="">{{ obj.requested_item_qty }} {{ obj.item_uom }}</td>
                <td class="text-center" v-if="dataDetailRetur.status === 'ACCEPTED'">{{ `${obj && obj.approved_item_qty} ${obj && obj.item_uom}` }}</td>
                <td width="170">{{ `Rp. ${toCurrency(obj && obj.return_price)}` }}</td>
                <td width="170" v-if="dataDetailRetur.status !== 'ACCEPTED'">{{ `Rp. ${toCurrency(obj && obj.subtotal_requested)}` }}</td>
                <td width="170" v-else>{{ `Rp. ${toCurrency(obj && obj.subtotal_approved)}` }}</td>
                <td class="">
                  <div class="d-flex align-items-center" style="gap: 0px 15px;">
                    <a-tooltip>
                      <template #title>Detail Produk</template>
                      <div @click.prevent="() => onClickDetailAction(obj)" class="d-flex align-items-center" style="cursor: pointer;">
                        <ActionViewIcon :width="'20'" :height="'20'" />
                        <span class="btn-action-label ml-1">Detail</span>
                      </div>
                    </a-tooltip>
                  </div>
                </td>
              </tr>
              <tr class="text-left tr-product-list table-body summary-col" v-if="dataDetailRetur.status !== 'ACCEPTED'">
                <td class="px-2"></td>
                <td class="px-2"></td>
                <td width="320" colspan="3" class="px-4" style="text-align: right;">Estimasi Total Pengembalian Dana : </td>
                <td class="py-3"><b>{{ `Rp. ${toCurrency(dataDetailRetur && dataDetailRetur.total_amount_requested)}` }}</b></td>
                <td class=""></td>
              </tr>
              <tr class="text-left tr-product-list table-body summary-col" v-else>
                <td class="px-2"></td>
                <td class="px-2"></td>
                <td width="320" colspan="3" class="px-4" style="text-align: right;">Total Pengembalian Dana : </td>
                <td class="py-3"><b>{{ `Rp. ${toCurrency(dataDetailRetur && dataDetailRetur.total_amount_approved)}` }}</b></td>
                <td class=""></td>
              </tr>
              <tr class="text-left tr-product-list table-body summary-col" v-if="dataDetailRetur.status !== 'ACCEPTED'">
                <td class="px-2"></td>
                <td class="px-2"></td>
                <td width="320" colspan="3" class="px-4" style="text-align: right;">Estimasi Total Pemotongan Kino Koin : </td>
                <td class="py-3"><b>{{ toCurrency(estimateCoin) }}</b></td>
                <td class=""></td>
              </tr>
              <tr class="text-left tr-product-list table-body summary-col" v-else>
                <td class="px-2"></td>
                <td class="px-2"></td>
                <td width="320" colspan="3" class="px-4" style="text-align: right;">Total Pemotongan Kino Koin : </td>
                <td class="py-3"><b>{{ toCurrency(estimateCoin) }}</b></td>
                <td class=""></td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import EmptyIcon from '@/components/Icons/Empty.vue'
import TablesAntdRowSelection from '@/views/tables/antd/examples/row-selection/index'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'
import ActionViewIcon from '@/components/Icons/ActionView.vue'
import ActionEditIcon from '@/components/Icons/ActionEdit.vue'
import ActionDeleteIcon from '@/components/Icons/ActionDelete.vue'
import CloseIcon from '@/components/Icons/Close.vue'
import BigLoader from '@/components/Icons/BigLoader.vue'
import ModalDetailProduct2 from '@/views/account/retur/create-retur/modal-detail-product2.vue'
import OrderIcon from '@/components/Icons/Order.vue'
import ProcessIcon from '@/components/Icons/Process.vue'
import OrderCancelIcon from '@/components/Icons/OrderCancel.vue'
import Vue from 'vue'
import BoxIcon from '@/components/Icons/Box.vue'

const staticListOrderProcess = [
  {
    title: 'Pengajuan',
    code: ['REQUESTED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dalam Proses',
    code: ['ON_PROCESS', 'ACKNOWLEDGED'],
    active: false,
    component: Vue.extend(ProcessIcon),
  },
  {
    title: 'Selesai',
    code: ['ACCEPTED'],
    active: false,
    component: Vue.extend(BoxIcon),
  },
]
const staticListOrderCancel = [
  {
    title: 'Pengajuan',
    code: ['REQUESTED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dalam Proses',
    code: ['ON_PROCESS'],
    active: false,
    component: Vue.extend(ProcessIcon),
  },
  {
    title: 'Ditolak',
    code: ['REJECTED'],
    active: false,
    component: Vue.extend(OrderCancelIcon),
  },
]
export default {
  name: 'component-purchase-index',
  components: {
    EmptyIcon,
    TablesAntdRowSelection,
    WarehousePinIcon,
    ActionViewIcon,
    ActionEditIcon,
    ActionDeleteIcon,
    CloseIcon,
    BigLoader,
    ModalDetailProduct2,
  },
  data: function () {
    return {
      loading: true,
      data: [],
      listProductRetur: [],
      returId: '',
      dataDetailRetur: null,
      showModalDetailProduct: false,
      productDetail: null,
      listProcess: [],
      estimateCoin: 0,
      bgColor: {
        DRAFT: {
          bg: '#FFC700',
          color: '#000000',
        },
        COMPLETED: {
          bg: '#015CA1',
          color: '#ffffff',
        },
        CANCELED: {
          bg: '#FF0000',
          color: '#ffffff',
        },
        REJECTED: {
          bg: '#FF0000',
          color: '#ffffff',
        },
        VERIFIED: {
          bg: '#929292',
          color: '#ffffff',
        },
        REQUESTED: {
          bg: '#929292',
          color: '#ffffff',
        },
        ACCEPTED: {
          bg: '#015CA1',
          color: '#ffffff',
        },
        SUBMITTED: {
          bg: '#929292',
          color: '#ffffff',
        },
        DELIVERED: {
          bg: '#F5831F',
          color: '#ffffff',
        },
        REQUESTED: {
          bg: '#929292',
          color: '#ffffff',
        },
        ACKNOWLEDGED: {
          bg: '#F5831F',
          color: '#ffffff',
        },
      },
      listTab: [
        {
          title: 'Semua',
          key: 0,
          value: ['DRAFT,REQUESTED,ACKNOWLEDGED,ACCEPTED,REJECTED,CANCELED'],
          state: ['DRAFT,REQUESTED,ACKNOWLEDGED,ACCEPTED,REJECTED,CANCELED'],
        },
        {
          title: 'Draft',
          key: 1,
          value: ['DRAFT'],
          state: ['DRAFT'],
        },
        {
          title: 'Pengajuan',
          key: 2,
          value: ['REQUESTED'],
          state: ['REQUESTED'],
        },
        {
          title: 'Dalam Proses',
          key: 3,
          value: ['ACKNOWLEDGED'],
          state: ['ACKNOWLEDGED'],
        },
        {
          title: 'Selesai',
          key: 4,
          value: ['COMPLETED','ACCEPTED'],
          state: ['COMPLETED','ACCEPTED'],
        },
        {
          title: 'Ditolak',
          key: 5,
          value: ['CANCELED', 'REJECTED'],
          state: ['CANCELED', 'REJECTED'],
        },
      ],
    }
  },
  computed: {
    getRequestReturDate() {
      return moment(this.dataDetailRetur?.requested_date).format('DD MMM YYYY')
    },
    getProcessReturDate() {
      return moment(this.dataDetailRetur?.processed_date).format('DD MMM YYYY')
    },
    getStatusRetur() {
      return this.listTab.find(item => item.state.includes(this.dataDetailRetur?.status))?.title
    },
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
  methods: {
    getWarehouseIdAndFetchBaseQty(seller_id, arrReturnItems) {
      this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
        channel_id: this.$store.state.user.user_data.channel_id,
        seller_id: [seller_id],
      })
      .then(({ data }) => {
        if (data.length > 0) {
          this.warehouseId = data[0].warehouse_id
          const arrCatalogId = arrReturnItems?.map((obj) => obj.catalog_id)
          const uniqueCatalogId = [...new Set(arrCatalogId)]
          this.getBaseQtyProduct(uniqueCatalogId, data[0].warehouse_id)
        }
      })
    },
    getDraftReturItems() {
      this.$store.dispatch('retur/GETRETURDETAIL', {
        channel_id: this.$store.state.user.user_data.channel_id,
        retur_number: this.$route.params.id,
      })
      .then(({ data }) => {
        this.getWarehouseIdAndFetchBaseQty(data.seller_id, data?.return_items)
        if (data?.return_items?.length > 0) {
          this.dataDetailRetur = data
          this.listProductRetur = data?.return_items
          this.estimateCoin = 0
          data?.return_items.forEach(item => {
            this.estimateCoin += item.return_coin
          })
          this.returId = data.id
          let active = true
          const newListProcess = ((data && data.status === 'REJECTED') ? staticListOrderCancel : staticListOrderProcess).map((item) => {
            const newItem = {
              ...item,
              active: item.code.includes(data.status) ? true : active,
            }
            if (item.code.includes(data.status)) {
              active = false
            }
            return newItem
          })
          this.listProcess = newListProcess
          const arrProductId = data?.return_items?.map((obj) => obj.product_id)
          const uniqueProductId = [...new Set(arrProductId)]
          this.getImageProduct(uniqueProductId)
        } else {
          this.listProductRetur = []
        }
      })
      .catch(() => {
        this.loading = false
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getImageProduct(arrProductId) {
      const promises = []
      arrProductId.map((obj) => {
        promises.push(
          this.$store.dispatch('product/GETPRODUCTDETAIL', {
            product_id: obj,
            channel_id: this.$store.state.user.user_data.channel_id,
          })
        )
      })
      Promise.all(promises)
        .then((data) => {
          if (data?.length > 0) {
            const listProductDetail =  data?.map((obj, index) => {
              return {
                img_url: obj.showimg_url,
                product_id: arrProductId[index]
              }
            })
            this.listProductRetur = this.listProductRetur?.map((obj) => {
              return {
                ...obj,
                img_url: listProductDetail?.find((item) => item.product_id === obj.product_id)?.img_url
              }
            })
          }
          this.loading = false
        })
        .catch(() => {
          this.$notification.error({
            message: 'failure get image detail product',
          })
        })
    },
    getBaseQtyProduct(arrCatalogId, whsId) {
      this.$store.dispatch('product/GETBASEQTYUOM', {
        channel_id: this.$store.state.user.user_data.channel_id,
        warehouse_id: whsId,
        catalog_id: arrCatalogId.join(','),
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.listProductRetur = this.listProductRetur?.map((obj) => {
              return {
                ...obj,
                base_qty: data?.find((item) => item.catalog_id === obj.catalog_id)
              }
            })
          }
        })
        .catch(() => {
          this.$notification.error({
            message: 'failure get base qty product',
          })
        })
    },
    getQtyBasedOnUOM(item) {
      const _qtyVal = this.dataDetailRetur?.status !== 'ACCEPTED' ? item?.requested_item_qty : item?.approved_item_qty
      const _uomVal = item?.item_uom
      if (item?.base_qty) {
        const selectedUOM = item?.base_qty?.units?.find((obj) => obj.unit.toUpperCase() === _uomVal.toUpperCase())
        if (selectedUOM) {
          return `${_qtyVal / selectedUOM.numerator * selectedUOM.denumerator} ${_uomVal}`
        } else {
          return null
        }
      } else {
        return '-'
      }
    },
    onClickDetailAction(productDetail) {
      this.showModalDetailProduct = true
      this.productDetail = productDetail
    },
    emitShowModalDetailProduct(val) {
      this.showModalDetailProduct= val
    },
  },
  mounted() {
    this.getDraftReturItems()
  },
  destroyed() {
  },
}
</script>

<style lang="scss">
.active {
  background: #E8F6FE;
  border: 1px solid #015CA1 !important;
  border-radius: 12px;
  color: #015CA1;
}

.inactive {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  color: #929292;
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;;
}

.action-button {
  border-radius: 8px;
  padding: 5px 40px;
}

.status-tag {
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 20px;
}

.desc-empty-state {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #015CA1;
}
.sub-title {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.warehouse-name {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.desc-empty-filter {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
.table-header {
  background-color: #F4F4F4;
  color: #767676;
  th {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 10px 10px 0px;
  }
  th:first-child{
    border-radius: 6px 0 0 0;
  }
  th:last-child{
    border-radius: 0 6px 0 0;
  }
}
.table-body {
  td {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }
}
.btn-action-label {
  color: #015CA1;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.container-info-retur {
  p {
    margin: 0px;
    font-family: Open Sans;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    &.label {
      font-weight: 600;
      color: #767676;
    }
    &.value {
      font-weight: 400;
      color: #000000;
    }
  }
}
.tag-status {
  border-radius: 16px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  width: fit-content;
}
.payment-information {
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .confirm-payment {
    height: 33px;
    background: #FFFFFF;
    border: 1px solid #265c9b !important;
    // border-width: 1px;
    // border-color: #265C9B;
    border-radius: 5px;
    color: #265C9B;
    cursor: pointer;
  }

  .status-icon-done {
    background: #015CA1;
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }

  .status-icon-done-mobile {
    background: #015CA1;
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }

  .status-icon-pending {
    background: #D2D2D2;
    border-radius: 100px;
    width: 60px;
    height: 60px;
  }

  .status-icon-pending-mobile {
    background: #D2D2D2;
    border-radius: 100px;
    width: 40px;
    height: 40px;
  }

  .text-icon {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #015CA1;
  }

  .text-icon-mobile {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #015CA1;
  }

  .text-icon-pending {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #767676;
  }

  .text-cancel {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FF0000;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .title-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: light;
    font-size: 14px;
    line-height: 16px;
    color: #2B2B2B;
  }

  .total-payment {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #F5831F;
  }

  .status-payment-paid {
    background: #265C9B;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    width: 25%;
    border-radius: 16px;
    font-size: 14px;
  }

  .status-payment-unpaid {
    font-size: 14px;
    background: #F5831F;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    width: 25%;
    border-radius: 16px;
  }

  .status-payment-cancel {
    font-size: 14px;
    background: #FF0000;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    width: 25%;
    border-radius: 16px;
  }
}
.summary-col {
  border-bottom: 1px solid #F4F4F4;
  border-top: 1px solid #F4F4F4;
  padding: 24px 0;
}
.reason-reject {
  color: #FF0000;
  background-color: #FFEEEE;
  border-left: 4px solid #FF0000;
  padding: 13px;
  border-radius: 6px;
  max-width: 700px;
  .text-dark {
    font-weight: 200;
  }
}
</style>
