<template>
  <b-modal id="modal-detail-product" v-model="showModal" centered class="p-3" hide-footer :hide-header="true" :size="disclaimerExceedQty ? '400' : 'md'">
    <template v-if="!loading">
      <!-- view detail -->
      <div>
        <div slot="modal-header" class="d-flex align-items-center justify-content-between py-2 w-100">
          <span class="text-header-modal mb-0">Detail Produk Retur</span>
          <span @click="onCloseModal" class="mb-0" style="cursor: pointer;">
            <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2246 5.0175L15.7423 12.499L23.2246 19.9805C24.2553 21.0125 24.2553 22.6877 23.2273 23.7226C22.1939 24.76 20.5209 24.7574 19.4875 23.7253L12 16.2385L4.51247 23.7253C3.47909 24.7574 1.80609 24.76 0.772705 23.7226C-0.258011 22.6879 -0.258013 21.0127 0.775369 19.9805L8.25769 12.499L0.775369 5.0175C-0.258013 3.98543 -0.258011 2.31023 0.772705 1.27536C1.28813 0.756718 1.96732 0.5 2.64398 0.5C3.32065 0.5 3.99717 0.759386 4.5126 1.27282L12.0001 8.75964L19.4876 1.27282C20.0031 0.756716 20.6796 0.5 21.3562 0.5C22.0329 0.5 22.7121 0.759386 23.2275 1.27536C24.258 2.31023 24.258 3.98543 23.2246 5.0175Z" fill="var(--biz-brand-button)"/>
            </svg>
          </span>
        </div>
        <div class="mb-3 mt-3">
          <div class="w-100 mb-3 container-product-detail mb-3 d-flex justify-content-between">
            <div class="d-flex">
              <div class="mr-2">
                <img
                  alt="image-product"
                  :src="productDetail && productDetail.img_url"
                  style="width: 100px; height: 100px; border: solid 0.5px #767676; border-radius: 5px; object-fit: cover;"
                />
              </div>
              <div>
                <div class="text-product-title mb-1">{{ productDetail && productDetail.catalog_title }}</div>
                <div class="text-reason mb-1">{{ getReasonReferenceOrder }}</div>
              </div>
            </div>
            <div>
              <div class="px-3 py-1 tag-status" :style="{ background: bgColor[dataDetailRetur && dataDetailRetur.status].bg, color: bgColor[dataDetailRetur && dataDetailRetur.status].color }">{{ getStatusRetur }}</div>
            </div>
          </div>
          <div class="w-100 mb-3 d-flex flex-column" style="gap: 10px 0px;">
            <div class="container-retur-detail d-flex align-items-center justify-content-between my-1">
              <div class="text-title w-50">Referensi No. Order</div>
              <div class="text-value w-50 text-right">{{ productDetail && productDetail.order_number }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between my-1">
              <div class="text-title w-50">Qty</div>
              <div class="text-value w-50 text-right">{{ productDetail.requested_item_qty }} {{ productDetail.item_uom }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between my-1" v-if="productDetail.approved_item_qty">
              <div class="text-title w-50">Qty yang Disetujui</div>
              <div class="text-value w-50 text-right">{{ productDetail.approved_item_qty }} {{ productDetail.item_uom }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between my-1">
              <div class="text-title w-50">Harga Satuan</div>
              <div class="text-value w-50 text-right">Rp. {{ toCurrency(productDetail && productDetail.return_price) }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between my-1">
              <div class="text-title w-50">Total Harga</div>
              <div class="text-value w-50 text-right">Rp. {{ toCurrency(productDetail && productDetail.subtotal_requested) }}</div>
            </div>
            <div class="container-retur-detail d-flex align-items-center justify-content-between my-1">
              <div class="text-title w-50">Nilai Kino Koin</div>
              <div class="text-value w-50 text-right">-{{ toCurrency(productDetail && productDetail.return_coin) }}</div>
            </div>
          </div>
        </div>
        <a-row>
          <a-col :xs="24" :sm="24" :md="24">
            <a-button
              @click.stop="() => toOrderDetail(productDetail && productDetail.order_id)"
              type="primary"
              block
              class="mt-3"
              style="
                width: 100%;
                background: var(--biz-brand-button) !important;
                color: #FFFFFF !important;
                border: 1px solid var(--biz-brand-button) !important;
                border-radius: 12px !important;
              "
            >
              Lihat Detail Transaksi
            </a-button>
          </a-col>
        </a-row>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <BigLoader class="rotate-img mb-4"/>
        <div class="loader-text-title mb-3">Memproses Perubahan Jumlah Produk</div>
        <div class="loader-text-desc">Kino Siap sedang memproses perubahan jumlah produk untuk produk <b>{{ productDetail && productDetail.catalog_title }}</b></div>
      </div>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import BigLoader from '@/components/Icons/BigLoader.vue'

export default {
  name: 'modal-detail-product2',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
    productDetail: {
      type: Object,
      default: () => null,
    },
    propsIsEdit: {
      type: Boolean,
      default: () => false,
    },
    returId: {
      type: String,
      default: () => '',
    },
    getStatusRetur: {
      type: String,
      default: () => '',
    },
    bgColor: {
      type: Object,
      default: () => null,
    },
    dataDetailRetur: {
      type: Object,
      default: () => null,
    }
  },
  components: {
    BigLoader,
  },
  data: function () {
    return {
      loading: false,
      isEdit: false,
      qtyValue: '',
      selectedUOMValue: '',
      listUOM: [],
      getListBaseUOM: [],
      detailMaxOrderQty: null,
      disclaimerExceedQty: false,
      listReason: [
        {
          label: 'Produk cacat atau rusak',
          value: 'damaged_product',
        },
        {
          label: 'Produk mendekati expired',
          value: 'expired_product',
        },
        {
          label: 'Lain-lain',
          value: 'other_reason',
        },
      ],
    }
  },
  computed: {
    getReasonReferenceOrder() {
      const findSelectedReason = this.listReason.find((obj) => obj.value === this.productDetail?.request_reason)
      return findSelectedReason ? findSelectedReason.label : this.productDetail?.request_reason
    },
  },
  methods: {
    onCloseModal() {
      this.$emit('emitShowModal', false)
      this.isEdit = false
      this.qtyValue = ''
      this.selectedUOMValue = ''
      this.listUOM = []
      this.getListBaseUOM = []
      this.disclaimerExceedQty = false
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    toOrderDetail(order_id) {
      this.$router.push({ path: `/purchase/order/${order_id}` });
    },
    getQtyBasedOnUOM(item) {
      const _qtyVal = this.dataDetailRetur?.status !== 'ACCEPTED' ? item?.requested_item_qty : item?.approved_item_qty
      const _uomVal = item?.item_uom
      if (item?.base_qty) {
        const selectedUOM = item?.base_qty?.units?.find((obj) => obj.unit.toUpperCase() === _uomVal.toUpperCase())
        if (selectedUOM) {
          return `${_qtyVal / selectedUOM.numerator * selectedUOM.denumerator} ${_uomVal}`
        } else {
          return null
        }
      } else {
        return '-'
      }
    },
  },
  watch: {
    showModal: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value === true) {
          this.isEdit = this.propsIsEdit
        }
      }
    },
  },
  mounted() {
  },
  destroyed() {
  },
}
</script>

<style lang="scss">
  div.modal .modal-dialog.modal-400 {
    width: 400px !important;
  }
  .text-header-modal {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .rotate-img {
    animation: rotation 1s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .loader-text-desc {
    //styleName: Body - KINO/MD/Regular;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    b {
      color: #015CA1;
    }
  }
  .loader-text-title {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .container-product-detail {
    .text-product-title {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    .text-reason {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #F5831F;
    }
  }
  .container-retur-detail {
    .text-title {
      font-family: Open Sans;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }
    .text-value {
      font-family: Open Sans;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #767676;
    }
  }
  .container-tag {
    background-color: #FFC700;
    border-radius: 16px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
  }
  .container-disclaimer-exceed-qty {
    .title {
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
    }

    .desc {
      p {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
      }
      b {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;

        &.blue {
          color: #015CA1;
        }
        &.orange {
          color: #F5831F;
        }
      }
    }
  }
</style>
