<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="12" fill="#FF0000"/>
  <path d="M17.6123 7.99258L13.8711 11.4995L17.6123 15.0065C18.1277 15.4903 18.1277 16.2755 17.6136 16.7606C17.0969 17.2469 16.2604 17.2456 15.7438 16.7618L12 13.2524L8.25623 16.7618C7.73954 17.2456 6.90304 17.2469 6.38635 16.7606C5.87099 16.2756 5.87099 15.4903 6.38768 15.0065L10.1288 11.4995L6.38768 7.99258C5.87099 7.50879 5.87099 6.72355 6.38635 6.23845C6.64406 5.99534 6.98366 5.875 7.32199 5.875C7.66032 5.875 7.99859 5.99659 8.2563 6.23726L12.0001 9.74671L15.7438 6.23726C16.0015 5.99534 16.3398 5.875 16.6781 5.875C17.0165 5.875 17.3561 5.99659 17.6138 6.23845C18.129 6.72355 18.129 7.50879 17.6123 7.99258Z" fill="white"/>
</svg>

</template>

<script>
export default {
  name: 'Close',
  props: {
    rotate: String,
  },
}
</script>
